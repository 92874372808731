var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c(
                "van-cell",
                { staticClass: "van-cell-headline" },
                [
                  Number(_vm.appForm.logisticsStatus) === 1
                    ? _c(
                        "van-button",
                        {
                          staticClass: "bt-left",
                          attrs: {
                            color: "#4B7902",
                            plain: "",
                            round: "",
                            size: "small"
                          }
                        },
                        [_vm._v(" 港 ")]
                      )
                    : _vm._e(),
                  Number(_vm.appForm.logisticsStatus) === 0
                    ? _c(
                        "van-button",
                        {
                          staticClass: "bt-left",
                          attrs: {
                            color: "#1373CC",
                            plain: "",
                            round: "",
                            size: "small"
                          }
                        },
                        [_vm._v(" 途 ")]
                      )
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        "提单号:" +
                          _vm.utils.isEffectiveCommon(_vm.appForm.pickUpNo)
                      )
                    )
                  ]),
                  _vm.appForm.pushCustomsStatus === 1
                    ? _c("p", { staticClass: "lf" }, [_vm._v(" 推送报关 ")])
                    : _vm._e()
                ],
                1
              ),
              _c("van-cell", {
                attrs: {
                  title: "合同号（PI/SC）：",
                  value: _vm.utils.isEffectiveCommon(_vm.appForm.pi)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c("van-cell", [
            _c(
              "div",
              { staticClass: "goods-table" },
              _vm._l(_vm.appForm.logisticsPickUpInfoVoList, function(
                item,
                idx
              ) {
                return _c(
                  "table",
                  {
                    key: item.logisticsCabinetNoId,
                    staticClass: "inner-goods-table",
                    attrs: { border: "1" }
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "header-th", attrs: { colspan: "2" } },
                        [_c("span", [_vm._v(_vm._s(idx + 1))])]
                      )
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("柜号")]),
                      _c("td", [_vm._v(_vm._s(item.cabinetNo))])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("货物名称")]),
                      _c("td", [_vm._v(_vm._s(item.cargoName))])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("国家")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.utils.statusFormat(item.country, "Country")
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("原产地")]),
                      _c("td", [_vm._v(_vm._s(item.countryOfOrigin))])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("件数")]),
                      _c("td", [_vm._v(_vm._s(item.pieceNumber))])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("实发重量（KG）")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.utils.moneyFormat(item.actualWeight)))
                      ])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("原币总金额(USD)")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.utils.moneyFormat(item.money)))
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }