<template>
  <div class="van-main">
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell class="van-cell-headline">
          <van-button v-if="Number(appForm.logisticsStatus) === 1" color="#4B7902" plain round size="small" class="bt-left">
            港
          </van-button>
          <van-button v-if="Number(appForm.logisticsStatus) === 0" color="#1373CC" plain round size="small" class="bt-left">
            途
          </van-button>
          <span>{{ `提单号:${utils.isEffectiveCommon(appForm.pickUpNo)}` }}</span>
          <p v-if="appForm.pushCustomsStatus === 1" class="lf">
            推送报关
          </p>
        </van-cell>
        <van-cell title="合同号（PI/SC）：" :value="utils.isEffectiveCommon(appForm.pi)" />
      </van-cell-group>
    </div>
    <div class="van-common-detail">
      <van-cell>
        <div class="goods-table">
          <table v-for="(item,idx) in appForm.logisticsPickUpInfoVoList" :key="item.logisticsCabinetNoId" class="inner-goods-table" border="1">
            <tr>
              <th colspan="2" class="header-th">
                <span>{{ idx+1 }}</span>
              </th>
            </tr>
            <tr>
              <td>柜号</td><td>{{ item.cabinetNo }}</td>
            </tr>
            <tr>
              <td>货物名称</td><td>{{ item.cargoName }}</td>
            </tr>
            <tr>
              <td>国家</td><td>{{ utils.statusFormat(item.country,'Country') }}</td>
            </tr>
            <tr>
              <td>原产地</td><td>{{ item.countryOfOrigin }}</td>
            </tr>
            <tr>
              <td>件数</td><td>{{ item.pieceNumber }}</td>
            </tr>
            <tr>
              <td>实发重量（KG）</td><td>{{ utils.moneyFormat(item.actualWeight) }}</td>
            </tr>
            <tr>
              <td>原币总金额(USD)</td><td>{{ utils.moneyFormat(item.money) }}</td>
            </tr>
          </table>
        </div>
      </van-cell>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
    </van-row>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data () {
    return {
      appForm: {},
      finishedText: '没有更多了',
      page: 1,
      pass: false,
      refuse: false,
      back: false,
      finished: false,
      refreshing: false,
      showGoods: false,
      tableList: []
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.billofloading.detail(this.$route.query.id).then(result => {
        this.appForm = result.data.value || {}
      })
    }
  }
}
</script>
  <style lang="less">
    .header-th{
      text-align: center;
    }
.lf{
  float: right;
  color: #F46112
}
.goods-table{
margin-bottom: 1rem;
}
.goods-table td {
padding: 5px;
}
  </style>
